/* eslint-disable no-unused-expressions,no-sequences,camelcase */
import axios from 'axios' ;
import { ServerStatistics } from "@constants/servers" ;
import qs from "qs" ;
import { ResultItem } from "@scripts/resultItem" ;
import { Store } from "vuex" ;
import { isDev } from "../utils" ;
import dayjs from "dayjs";

/** 加入统计的白名单 */
export const AnalysisWhiteList = [
    `p.bigbigwork.com`,
    `www.bigbigwork.com`,
    `www-test.bigbigwork.com`,
    `www-test2.bigbigwork.com`,
    `www-test3.bigbigwork.com`,
    `search.bigbigwork.com`,
    `p-test.bigbigwork.com`,
    `searchtest.bigbigwork.com`,
    `www-local.bigbigwork.com`,
    `www-test.pinlite.me`,
    `www.pinlite.me`,
    `www-test.pinlite.net`,
    `www.pinlite.net`,
    `www-test.pinlite.cc`,
    `www.pinlite.cc`,
    `local.pinlite.net`,
    `local.pinlite.cc`,
    `local.pinlite.me`,
    `www.ai-image.net`,
    `www.lookxiai.com`,
    `rabbit.bigbigwork.com`,
    `rabbit-test.bigbigwork.com`,
    `kuotu.bigbigwork.com`,
    `kuotu-test.bigbigwork.com`,
    `www-test.bigbigllll.com`,
    `www.bigbigllll.com`
] ;
/** host 是否在白名单 */
export const AnalysisIsInWhite = ( host: string ): boolean => {
    for ( let i = 0 ; i < AnalysisWhiteList.length ; i++ ) {
        if ( host.match(AnalysisWhiteList[i])  ) {
            return true ;
        }
    }
    isDev () && console.log ( window.location.hostname + `不在统计白名单` ) ;
    return false ;
} ;
/** 初始化bbw谷歌统计 */
export const AnalysisInitGoogle = () => {
    // @ts-ignore
    if ( !window.ga ) {
        // 初始化谷歌统计
        ( function ( i, s, o, g, r, a, m ) {
            // @ts-ignore
            i.GoogleAnalyticsObject = r ;
            i[r] = i[r] || function () {
                ( i[r].q = i[r].q || [] ).push ( arguments ) ;
                // @ts-ignore
            }, i[r].l = 1 * new Date () ;
            a = s.createElement ( o ),
            m = s.getElementsByTagName ( o )[0] ;
            a.async = 1 ;
            a.src = g ;
            m.parentNode.insertBefore ( a, m ) ;
        } ) ( window, document, `script`, `${process.env.CDN_WIMG_PATH}/js/analytics.js`, `ga` ) ;
    }
} ;

/** Pinlite百度统计的 id */
export const AnalysisBaiduPinliteId = `a073fb3d93f1ef97bcbdd462f98e2d8d` ;
/** 初始化百度统计 */
export const AnalysisInitBaidu = ( id: string ) => {
    // @ts-ignore
    if ( !window._hmt || ( window._hmt && !window._hmt.id ) ) {
        // @ts-ignore
        // `初始化百度统计`
        window._hmt = [] ;
        ( function () {
            var hm = document.createElement ( `script` ) ;
            hm.src = `https://hm.baidu.com/hm.js?${id}` ;
            var s = document.getElementsByTagName ( `script` )[0] ;
            s.parentNode.insertBefore ( hm, s ) ;
        } ) () ;
    }
} ;
/** 初始化友盟统计 */
export const AnalysisInitYouMeng = ( id?: string ) => {
    // @ts-ignore
    if ( !window._czc ) {
        // @ts-ignore
        // `初始化百度统计`
        window._czc = [] ;
        ( function () {
            var czc = document.createElement ( `script` ) ;
            czc.src = `https://s19.cnzz.com/z_stat.php?id=1274304640&web_id=1274304640` ;
            var s = document.getElementsByTagName ( `script` )[0] ;
            s.parentNode.insertBefore ( czc, s ) ;
        } ) () ;
    }
} ;
/** 初始化bbw360统计 */
export const AnalysisInit360 = () => {
    ( function ( b, a, e, h, f, c, g, s ) {
        b[h] = b[h] || function () {
            ( b[h].c = b[h].c || [] ).push ( arguments ) ;
        } ;
        b[h].s = !!c ;
        g = a.getElementsByTagName ( e )[0] ;
        s = a.createElement ( e ) ;
        s.src = `//s.union.360.cn/` + f + `.js` ;
        s.defer = !0 ;
        s.async = !0 ;
        g.parentNode.insertBefore ( s, g ) ;
    } ) ( window, document, `script`, `_qha`, 147946, false ) ;
} ;

/** 发送统计数据到统计服务器 */
export const AnalysisSendAnalysis = ( type: string, data: any ): void => {
    // 如果没有搜索信息则返回
    if ( !type ) return ;
    // 初始化提交信息
    let formData = {} ;
    let url = `` ;
    // 定义一个变量有的参数不需要转成字符串
    let dataItem ;
    // 根据统计类型，计算数据类型
    switch ( type ) {
    case `openPay`:
        url = `/stats/openpay` ;
        formData = data ;
        break ;
    case `DownloadImage`:
        url = `/stats/user/download` ;
        formData = data ;
        break ;
    case `CollectImage`:
        url = `/stats/user/collect` ;
        formData = data ;
        break ;
    case `Search`:
        url = `/stats/user/word` ;
        formData = data ;
        break ;
    case `JhpinsImpression`:
        url = `/jh/statsJhpinsImpression` ;
        formData = data ;
        break ;
    case `JhpinsHits`:
        url = `/jh/statsJhpinsHits` ;
        formData = data ;
        break ;
    default:
        isDev () && console.log ( `没有定义的统计事件` ) ;
        break ;
    }
    isDev () && console.log ( formData ) ;
    // 发送数据
    if ( type === `JhpinsImpression` || type === `JhpinsHits` ) {
        dataItem = formData ;
    } else {
        dataItem = qs.stringify ( { body: encodeURI ( JSON.stringify ( formData ) ) } ) ;
    }
    axios ( {
        method: `POST`,
        url: ServerStatistics + url,
        data: dataItem
    } ).catch ( e => {
        throw e ;
    } ) ;
} ;

export const AnalysisCollToolIndex = ( options: {action_from: number, action_uid: number} ) => {
    axios ( {
        method: `POST`,
        url: `${ServerStatistics}/collection/collector/stats`,
        headers: {
            "Content-Type": `application/json; charset=utf-8`
        },
        data: JSON.stringify ( options )
    } ).catch ( e => {
        throw e ;
    } ) ;
} ;

/** 大图页相关图下载、收藏kibana统计 */
export const AnalysisCorrelationalStatistic = ( data ) => {
    axios ( {
        url: ServerStatistics + `/relatedImg/clickHits/stats`,
        params: data
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `大图页相关图下载、收藏kibana统计` + `统计失败` ) ;
    } ) ;
} ;
/** 首页board kibana统计 */
export const AnalysisHomePageStatistic = ( data ) => {
    axios ( {
        url: ServerStatistics + `/homePage/clickHits/stats`,
        params: data
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `大图页相关图下载、收藏kibana统计` + `统计失败` ) ;
    } ) ;
} ;
/** 图意短句点击 kibana统计 */
export const AnalysisTUYiDUanJuStatistic = ( data ) => {
    axios ( {
        url: ServerStatistics + `/tuyi/duanju/click`,
        method: `POST`,
        data
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `图意推荐短句点击统计` + `统计失败` ) ;
    } ) ;
} ;
/** board的board显示 kibana统计 */
export const AnalysisShowStats = ( data ) => {
    axios ( {
        url: ServerStatistics + `/boardAssociateBoard/impression/stats`,
        params: data
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `大图页相关图下载、收藏kibana统计` + `统计失败` ) ;
    } ) ;
} ;
/** board的board点击显示 kibana统计 */
export const AnalysisClickStats = ( data ) => {
    axios ( {
        url: ServerStatistics + `/boardAssociateBoard/hits/stats`,
        params: data
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `大图页相关图下载、收藏kibana统计` + `统计失败` ) ;
    } ) ;
} ;
/** 进入到人的页面时kibana统计 */
export const AnalysisOpenPersonPage = ( data ) => {
    axios ( {
        url: ServerStatistics + `/userOpenPersonPage/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `打开人的页面kibana统计` + `统计失败` ) ;
    } ) ;
} ;
/** 进入到大图页时kibana统计 */
export const AnalysisUserOpenBigImg = ( data ) => {
    axios ( {
        url: ServerStatistics + `/userOpenBigImg/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `进入到大图页时kibana统计` + `统计失败` ) ;
    } ) ;
} ;
/** 进入到board页面时kibana统计 */
export const AnalysisUserOpenBoard = ( data ) => {
    axios ( {
        url: ServerStatistics + `/userOpenBoard/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `进入到board页面时kibana统计` + `统计失败` ) ;
    } ) ;
} ;
/** 大作搜索打开大图kibana统计 */
export const AnalysisOpenDzSeoImg = ( data ) => {
    axios ( {
        url: ServerStatistics + `/userOpenDzSeoImg/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `大作搜索打开大图kibana统计` + `统计失败` ) ;
    } ) ;
} ;

/** 大作搜索大图所在board的显示kibana统计 */
export const AnalysisBoardDisplay = ( data ) => {
    axios ( {
        url: ServerStatistics + `/inOtherBoard/boardDisplay/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `大作搜索打开大图kibana统计` + `统计失败` ) ;
    } ) ;
} ;

/** 大作搜索大图board的点击量kibana统计 */
export const AnalysisBoardClickVolume = ( data ) => {
    axios ( {
        url: ServerStatistics + `/inOtherBoard/boardClickVolume/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `大作搜索打开大图kibana统计` + `统计失败` ) ;
    } ) ;
} ;

/** 大作首页修改感兴趣领域位置点击量kibana统计 */
export const AnalysisChangeLikefields = ( data ) => {
    axios ( {
        url: ServerStatistics + `/likefields/change/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `修改感兴趣领域kibana统计` + `统计失败` ) ;
    } ) ;
} ;

/** 相似图打开统计点击量kibana统计 */
export const AnalysisSimilarImgButton = ( data ) => {
    axios ( {
        url: ServerStatistics + `/similarimgbutton/hits/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `相似图打开kibana统计` + `统计失败` ) ;
    } ) ;
} ;

/** 全球找大图，下载kibana统计 */
export const AnalysisDownloadStatistics = ( data ) => {
    axios ( {
        url: ServerStatistics + `/globalsearch/download/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `全球找大图下载统计` + `统计失败` ) ;
    } ) ;
} ;

/** 大作搜索 用户点击关联词统计 */
export const AnalysisBoardAssociateWord = ( data ) => {
    axios ( {
        url: ServerStatistics + `/relatedWord/clickHits/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `大作搜索` + `用户点击关联词统计` + `统计失败` ) ;
    } ) ;
} ;

/** board关联词展示统计 */
export const AnalysisRelatedWordDisplay = ( data ) => {
    axios ( {
        url: ServerStatistics + `/boardAssociateWord/impression/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        } else {
            isDev () && console.log ( `board` + `关联词展示统计` + `统计成功` ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `board` + `关联词展示统计` + `统计失败` ) ;
    } ) ;
} ;

/** 搜索图片来源统计 */
export const AnalysisSearchImpressions = ( data ) => {
    axios ( {
        url: ServerStatistics + `/bigworksearch/impressions/stats`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        isDev () && console.log ( `发送` + `大作搜索打开大图kibana统计` + `统计失败` ) ;
    } ) ;
} ;

/** 收藏统计消息类型 */
export type AnalysisCollectionData = {
    search_word: string, // 搜索词
    search_word_en: string, // 搜索词英文
    img_class: number, // 图片分类
    img_class_string: string, // 图片分类名称
    img_source_website: string, // 图片网站
    img_actual_website: string, // 图片所在网页链接(url或domain)
    img_name: string, // 图片名称
    img_describe: string, // 图片描述（暂不统计）
    img_designer_name: string, // 图片设计师
    userid: number, // 用户id
    username: string, // 用户昵称
    board_type: number, // 1个人  2 协作  （具体值看后期）
    timestamp: number, // 当前时间戳毫秒级别,
    resource: string, // 页面来源
    accessToken: string, // token
    flag_dz: number, // 来源 0:p; 1:www; 2:pinlite
    img_id: number | string,
    board_id: number | string, // boardid
    source_board_id: number | string // 原始boardid
}

/**
 * 下载统计消息类型
 * @see http://doc.bigbigwork.com/docs/kibana/1749
 */
export type AnalysisDownloadData = {
    search_word: string, // 搜索词
    search_word_en: string, // 搜索词英文
    img_class: number, // 图片分类
    img_class_string: string, // 图片分类名称
    img_source_website: string, // 图片网站
    img_actual_website: string, // 图片所在网页链接
    img_download_link: string, // 图片下载链接
    img_name: string, // 图片名称
    img_describe: string, // 图片描述（暂不统计）
    img_designer_name: string, // 图片设计师
    img_size: number, // 图片大小  500：500k
    img_width_height: string, // 图片宽高  500*600
    userid: number, // 用户id
    username: string, // 用户昵称
    is_speed: number, // 是否加速  0否  1是
    timestamp: number, // 当前时间戳毫秒级别
    accessToken: string, // token
    flag_dz: number | string, // 来源 0:p; 1:www; 2:pinlite
    img_id: number | string,
    resource: string,
    source_board_id: number | string,
    login_days: number,
    register_days: number
}
/** 搜索统计消息类型 */
export type AnalysisSearchData = {
    search_word: string, // 搜索词
    search_word_en: string, // 搜索词英文
    search_type: number, // 1 该分类全部网站  2  改分类部分网站  3 单一网站
    web_num: number, // 选择网站的数量
    web_name: string, // 选择网站的网站名，多网站，用英文逗号分隔
    view_type: string, // 1 快速浏览  2完全浏览
    img_type: string, // 图片分类
    img_type_string: string, // 图片分类名称
    userid: number, // 用户userid
    username: string, // 用户昵称
    first_open: number, // 打开第一张图片的时长 秒
    // vip: BigBigWork.User.isVip()?(BigBigWork.User.isUserPay.is_user_pay?1:2):0, //是否vip  0：非VIP  1：付费VIP 2：非付费VIP
    // timestamp: new Date().getTime(), //当前时间戳毫秒级别
    resource: string,
    accessToken: string
}

/** 相似图操作统计 */
export const AnalysisSimilarImgActionStat = ( uid: number, imgId: number, pageType: string, actionSource: string, sourceBoardId: number, actionType: string, actionImgId: number ) => {
    return axios ( {
        url: ServerStatistics + `/similarimg/openstats`,
        method: `POST`,
        data: qs.stringify ( {
            body: encodeURI ( JSON.stringify ( {
                u_id: uid,
                img_id: imgId,
                pageType: pageType,
                action_source: actionSource,
                source_board_id: sourceBoardId,
                action_type: actionType,
                action_img_id: actionImgId
            } ) )
        } )
    } ).then ( res => {
        if ( res.status !== 200 || res.data.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        throw e ;
    } ) ;
} ;
/**
 * @description 图片点击统计
 * @link http://doc.bigbigwork.com/docs/kibana_new/1945
 * @param options
 * @param item
 * @param store
 */

export const statImgClick = ( options: {
    search_word?: string,
    search_industry?: string,
    main_img_id?: string,
    main_img_source?: string,
    main_img_industry?: string,
    main_img_remark?: string,
    from_source?: number,
    pin_action_type: string,
    pin_from_page: string,
    pin_from_source?:number,
    from_page_url: string,
    pin_order?:number,
    ver?: string,
    [key:string]:any
}, item, store:Store<any>, servlet:string = `/statsDzpinsHits` ) => {
    try {
        const baseInfo = {
            action_uid: store.state.UserInfo.id,
            pin_id: item.id,
            pin_board_id: item.board_id,
            pin_owner_id: item.owner?.id || item.options?.imgOwner?.uid || item.authorId || ``,
            main_img_industry: options.main_img_industry,
            pin_industry: item.pin_industry,
            pin_origin_type: item.origin_type,
            pin_order: item.order,
            action_source: `pc`,
            bUrl: item.bUrl || ``,
            w: item.w,
            h: item.h,
            pin_from_page: location.href,
            title2: item.title_cn || item.description_cn
        } ;
        const data = Object.assign ( {}, baseInfo, options ) ;
        axios ( {
            url: `${ServerStatistics}${servlet}`,
            method: `POST`,
            data
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 跳转统计
 * @link http://doc.bigbigwork.com/docs/kibana_new/3425
 * @param options
 * @param store
 */

export const statSearchRedirect = ( options: {
    redirect_url: string,
    action_place: string,
    action_source: string,
}, store:Store<any>, servlet:string = `/pinlite/searchRedirect/stats` ) => {
    try {
        const baseInfo = {
            action_uid: store.state.UserInfo.id,
            usert_login_days: store.state.UserInfo.loginDays,
            usert_register_days: Math.ceil ( ( store.state.UserInfo.now - store.state.UserInfo.createTime ) / ( 24 * 60 * 60 * 1000 ) ) ,
            usert_vip_days: store.state.UserInfo.vipDays,
            from_page: location.href
        } ;
        const data = Object.assign ( {}, baseInfo, options ) ;
        axios ( {
            url: `${ServerStatistics}${servlet}`,
            method: `POST`,
            data
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 图片显示统计
 * @param data
 */
export const imgImpressionStat = async ( data: any ) => {
    try {
        axios ( {
            method: `post`,
            url: `${ServerStatistics}/recommendResource/stats/homeImg2Mq`,
            data
        } ) ;
    } catch ( e ) {
    }
} ;
/**
 * @description 有搜索词的搜索统计
 * @param data
 */
export const searchWordStat = ( data ) => {
    axios ( {
        url: ServerStatistics + `/stats/search/word`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        __DEV__ && console.log ( `大作搜索` + `搜索词` + `统计失败` ) ;
    } ) ;
} ;
/**
 * @description 打开board页面时统计
 * @link http://doc.bigbigwork.com/docs/kibana_new/2354
 * @param data
 */
export const startBoardsOpen = ( data ) => {
    axios ( {
        url: ServerStatistics + `/statsBoardsOpen`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        __DEV__ && console.log ( `打开` + `board页面` + `统计失败` ) ;
    } ) ;
} ;
/**
 * @description 打开首页时统计
 * @link http://doc.bigbigwork.com/docs/kibana_new/3151
 * @param data
 */
export const startHomeOpen = ( data ) => {
    axios ( {
        url: ServerStatistics + `/homeOpen`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        __DEV__ && console.log ( `打开` + `首页` + `统计失败` ) ;
    } ) ;
} ;
/**
 * @description 搜索我的图片统计
 * @link http://doc.bigbigwork.com/docs/bbw/2037
 * @param data
 */
export const searchMyPinStat = ( data ) => {
    axios ( {
        url: ServerStatistics + `/stats-pin-search`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        __DEV__ && console.log ( `搜索我的图片` + `统计失败` ) ;
    } ) ;
} ;

/**
 * @description 压缩图任务统计
 * @link http://doc.bigbigwork.com/docs/pc2022/3894
 * @param data
 */
export const searchCompressTask = ( data ) => {
    axios ( {
        url: ServerStatistics + `/stats/compress/picture/task`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        __DEV__ && console.log ( `压缩图任务统计` + `统计失败` ) ;
    } ) ;
} ;

/**
 * @description 压缩图下载统计
 * @link http://doc.bigbigwork.com/docs/pc2022/3894
 * @param data
 */
export const searchCompressDownload = ( data ) => {
    axios ( {
        url: ServerStatistics + `/stats/compress/picture/download`,
        method: `POST`,
        headers: {
            "Content-Type": `application/json`
        },
        data: JSON.stringify ( data )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new Error ( JSON.stringify ( res ) ) ;
        }
        // callback && callback(res);
    } ).catch ( e => {
        __DEV__ && console.log ( `压缩图下载统计` + `统计失败` ) ;
    } ) ;
} ;
/** 统计页面表现 */
export const AnalysisPagePerformance = async ( store:Store<any> ) => {
    try {
        const [entry] = ( performance.getEntriesByType("navigation") ) as unknown as PerformanceTiming[];
        const stats = async () => {
            // 如果加载太慢就统计
            if ( entry.loadEventEnd <= 1000 ) {
                return ;
            }
            const res = await axios ({
                url: ServerStatistics + `/stats/cdn/url/loading`,
                method: `post`,
                data: {
                    uid: store.state.UserInfo.id,
                    token: store.state.token || '-1',
                    url: window.location.href,
                    ua: window.navigator.userAgent,
                    domContentLoadedEvent: entry.domContentLoadedEventEnd,
                    loadEvent: entry.loadEventEnd,
                    request: entry.redirectEnd,
                    responseStart: entry.redirectStart,
                    responseEnd: entry.responseEnd,
                    // @ts-ignore
                    type: entry.type
                }
            });
        }
        if ( entry?.loadEventEnd ) {
            window.setTimeout (( ) => {
                stats () ;
            }, 1000 )
        }else{
            window.addEventListener("load", () => {
                window.setTimeout (( ) => {
                    stats () ;
                }, 1000 )
            })
        }
    }catch (e) {
        console.log(e)
    }
}

/**
 * @description 大作跳ai统计
 */
export const statsDzToAi = ( store:Store<any>, from = `dz大图`, img_id: string ) => {
    const register_time = dayjs ( store.state.UserInfo.createTime ).format ( `YYYY-MM-DD` ) ;
    const nowTime = dayjs ().format ( `YYYY-MM-DD` ) ;
    axios ( {
        url: ServerStatistics + `/ai/painting/aiDz2ai`,
        method: `POST`,
        data: {
            uid: store.state.UserInfo.id,
            from: from,
            from_imgid: img_id,
            register_time,
            flag_today: nowTime === register_time ? 1 : 0,
            dz_vip_days: store.state.UserInfo.vipDays,
            register_days: dayjs ().diff ( dayjs ( store.state.UserInfo.createTime ), `day` ) + 1 // 注册天数
        }
    } ) ;
} ;

/**
 * @description 通用功能统计（stats-ai-action ） http://doc.bigbigwork.com/docs/ai_hua/6996
 */
export const statsAIAction = ( store:Store<any>, option:{action_type:string, remark:string, source?:string} ) => {
    const { source = `pc` } = option ;
    axios ( {
        url: ServerStatistics + `/ai/painting/aiAction`,
        method: `POST`,
        data: {
            userid: store.state.UserInfo.id === -1 ? `` : store.state.UserInfo.id,
            ...option,
            source
        }
    } ) ;
} ;

/**
 * @description 大作统计 http://doc.bigbigwork.com/docs/tuyi/7128
 */export const statsDzActionClick = ( option:{store: Store<any>, action_type:string, source:string, remark:string, from_page?:string
} ) => {
    const { store, action_type = ``, source = `pc`, remark = ``, from_page = window.location.href } = option ;
    axios ( {
        url: ServerStatistics + `/dzActionClick`,
        method: `POST`,
        data: {
            action_uid: store.state.UserInfo.id,
            vip_days: store.state.UserInfo.vipDays,
            action_type,
            from_page,
            remark,
            source
        }
    } ) ;
} ;


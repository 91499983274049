import {
    PageLogin,
    PageLoginOut,
    PagePinliteLogin,
    PageRegister,
    PagePinliteRegister,
    PagePinliteLoginAuth,
    PagePinliteTupian,
    PageSearchAuth,
    PagePinliteAuth, PagePinliteMobileAuth, PageAiAuth, PageAiCreate, PageDkAuth
} from "@constants/pages" ;
import {ServerAiPainting, ServerWww} from "@constants/servers" ;
import { getBase64Image, getDomain, getToken, getUrlParam, jumpPage, waitTime } from "@scripts/tools" ;
import { SearchProxy } from "@scripts/Proxy" ;
import Cookies from "js-cookie" ;
import { isDev } from "@scripts/utils" ;
import qs from "querystring" ;
import { getTokenCrossDomain } from "./CrossDomain" ;
import { Store } from "vuex" ;
import type { BBWHistory } from "@scripts/CacheHistory" ;
import Router, { RawLocation } from "vue-router" ;

export const getUTMLink = () => {
    const search = window.location.search ;
    if ( search ) {
        const str = search.slice ( 1 ) ;
        const result = qs.parse ( decodeURIComponent ( str ) ) ;
        if ( result.redirect !== undefined ) {
            delete result.redirect ;
        }
        if ( result.source !== undefined ) {
            delete result.source ;
        }
        return qs.stringify ( result ) ;
    } else {
        return `` ;
    }
} ;
/** 跳转到登录页 */
export const goLogin = ( redirect = PageLoginOut ) => {
    const search = getUTMLink () ;
    window.open ( `${PageLogin}?${search}redirect=${redirect}` ) ;
} ;
/** 跳转到注册页 */
export const goRegister = ( redirect = PageLoginOut ) => {
    const search = getUTMLink () ;
    window.open ( `${PageRegister}?${search}redirect=${redirect}` ) ;
} ;
/** 跳转到Pinlite登录页 */
export const goPinliteLogin = ( redirect = PagePinliteTupian ) => {
    const search = getUTMLink () ;
    // window.open ( getPinliteLogin ( redirect, search ) ) ;
    window.location.href = getPinliteLogin ( redirect, search ) ;
} ;
/** 获取Pinlite注册页地址 */
export const getPinliteLogin = ( redirect = PagePinliteTupian, search = `` ) => {
    const inviteCode = getUrlParam ( `inviteCode` ) ;
    const inviteParam = inviteCode ? `&inviteCode=${inviteCode}` : `` ;
    const redirectUrl = encodeURIComponent ( `${PagePinliteLoginAuth}?redirect=${redirect}` ) ;
    return `${PagePinliteLogin}?${search}&source=pc_pinlite&redirect=${redirectUrl}${inviteParam}` ;
} ;
/**
 * 跳转到Pinlite注册页
 * @description
 * 跳转到p.bigbigwork.com/loginAuth.html后，
 * 带上token和redirect自动跳转到pinlite.net/auth，验证登陆后跳转到redirect
 * @param redirect
 */
export const goPinliteRegister = ( redirect = `https:` + PagePinliteTupian ) => {
    const search = getUTMLink () ;
    window.location.href = getPinliteRegister ( redirect, search ) ;
} ;
/** 获取Pinlite注册页地址 */
export const getPinliteRegister = ( redirect = `https:` + PagePinliteTupian, search = `` ) => {
    const inviteCode = getUrlParam ( `inviteCode` ) ;
    const inviteParam = inviteCode ? `&inviteCode=${inviteCode}` : `` ;
    const redirectUrl = encodeURIComponent ( `${PagePinliteLoginAuth}?redirect=${redirect}` ) ;
    return `${PagePinliteRegister}?${search}&source=pc_pinlite&redirect=${redirectUrl}${inviteParam}` ;
} ;
export const goSembPagePinlite = async ( img, $store ) => {
    try {
        const type = `Pinlite` ;
        const popup = window.open () ;
        const imgDataUrl = await getBase64Image ( img ) ;
        // console.log( "base64", imgDataUrl );
        const collectPage = isDev () ? `https://www-test.bigbigwork.com/SemblancePic/dzcollect.html` : `https://www.bigbigwork.com/SemblancePic/dzcollect.html` ;
        const toUrl = `${collectPage}?page_type=${encodeURIComponent ( type )}` ;
        const openUrl = getAuthUrl ( $store, toUrl ) ;
        console.log ( `openUrl`, openUrl ) ;
        popup.name = imgDataUrl ;
        popup.location.href = openUrl ;
    } catch ( e ) {
        console.error ( e ) ;
        throw e ;
    }
} ;
/** 跳转到相似图页面 */
export const goSembPage = async ( item, type: `全球推荐`|`聚合搜索`|`Pinlite`| `免费版权` | string = ``, box = null, index = null, $store = null ) => {
    console.log ( `跳相似图`, item ) ;
    if ( item.type === `meta` ) {
        let url ;
        if ( type === `Pinlite` ) {
            url = SearchProxy.getImgUrl ( item.bUrl, `bigImage`, item ) ;
        } else {
            url = SearchProxy.getImgUrl ( item.bUrl, `newBigImage`, item ) ;
        }
        if ( $store ) {
        //    增加签名校验
            const sign = $store.state.PinUser.sign ;
            const token = await getTokenCrossDomain () ;
            url = `${url}&sign=${sign}&token=${token}` ;
        }
        const imgDataUrl = await getBase64Image ( url ) ;
        const collectPage = isDev () ? `https://www-test.bigbigwork.com/SemblancePic/dzcollect.html` : `https://www.bigbigwork.com/SemblancePic/dzcollect.html` ;
        const toUrl = `${collectPage}?page_type=${encodeURIComponent ( type )}` ;
        const openUrl = getAuthUrl ( $store, toUrl ) ;
        window.open ( openUrl, imgDataUrl ) ;
    } else if ( !box && typeof ( box ) !== `undefined` && box != 0 && !index && typeof ( index ) !== `undefined` && index != 0 ) {
        const toUrl = ServerWww + `/SemblancePic/` + item.id + `.html?page_type=` + encodeURIComponent ( type ) ;
        const openUrl = getAuthUrl ( $store, toUrl ) ;
        window.open ( openUrl, `_blank` ) ;
    } else {
        const spot = JSON.stringify ( box ) ;
        // const url = `http://www-test2.bigbigwork.com:8099/SemblancePic/`+item.id +`.html?page_type=` + type + `&curbox=` + encodeURIComponent(spot) + `&curIdx=` + index;
        const toUrl = ServerWww + `/SemblancePic/` + item.id + `.html?page_type=` + type + `&curbox=` + encodeURIComponent ( spot ) + `&curIdx=` + index ;
        const openUrl = getAuthUrl ( $store, toUrl ) ;
        window.open ( openUrl, `_blank` ) ;
    }
} ;

export const goLoginOut = async ( redirect = ``, url = `` ) => {
    try {
        const env = isDev () ? `dev` : `prod` ;
        const token = await getTokenCrossDomain () ;
        Cookies.remove ( `jhk-personal`, { domain: getDomain (), path: `/` } ) ;
        Cookies.remove ( `dashisousuo`, { domain: getDomain (), path: `/` } ) ;
        Cookies.remove ( env + `updateUnreadTime`, { domain: getDomain (), path: `/` } ) ;
        Cookies.remove ( env + `msgUnReadCount`, { domain: getDomain (), path: `/` } ) ;
        console.log ( PageLoginOut + `?` + ( redirect ? `&redirect=${redirect}` : `` ) + `&token=${token}` ) ;
        if ( redirect === `bigbigcool` ) {
            window.location.href = PageLoginOut + `?` + ( redirect ? `&redirect=${redirect}` : `` ) + `&token=${token}` + `&url=${encodeURIComponent ( url )}` ;
        } else {
            window.location.href = PageLoginOut + `?` + ( redirect ? `&redirect=${redirect}` : `` ) + `&token=${token}` ;
        }
    } catch ( e ) {
        throw e ;
    }
} ;
/* 跳转大作图片集 */
export const goBoards = async ( $store = null, uuid = ``, word ) => {
    try {
        if ( uuid ) {
            const url = isDev () ? `https://www-test.bigbigwork.com/board/${uuid}` : `https://www.bigbigwork.com/board/${uuid}` ;
            const openUrl = getAuthUrl ( $store, url ) ;
            window.open ( openUrl, `_blank` ) ;
        } else {
            const url = isDev () ? `https://www-test.bigbigwork.com/searchboards/?w=${word}&h=全部分类&color=` : `https://www.bigbigwork.com/searchboards/?w=${word}&h=全部分类&color=` ;
            const openUrl = getAuthUrl ( $store, url ) ;
            window.open ( openUrl, `_blank` ) ;
        }
    } catch ( e ) {
        throw e ;
    }
} ;

/** 获得大作登录url */
export const getAuthUrl = ( store:Store<any>, url:string ) => {
    if ( store.state.token ) {
        return `${PageSearchAuth}?token=${encodeURIComponent ( store.state.token )}&redirect=${encodeURIComponent ( url )}` ;
    } else {
        return url ;
    }
} ;
/** 获取ai 登录url */
export const getAIAuthUrl = ( store:Store<any>, url:string ) => {
    if ( store.state.token ) {
        return `${ServerAiPainting}/auth?token=${encodeURIComponent ( store.state.token )}&redirect=${encodeURIComponent ( url )}` ;
    } else {
        return url ;
    }
} ;
/** 获得pinlite登录url */
export const getPinliteAuthUrl = ( store:Store<any>, redirect:string, platform = `PC` ) => {
    if ( store.state.token ) {
        const prefix = platform.toUpperCase () === `M` ? `m` : `www` ;
        const url = `https://${prefix}${__DEV__ ? `-test` : ``}.${store.state.PinUser.pinliteDomain}/auth` ;
        return `${url}?token=${encodeURIComponent ( store.state.token )}&redirect=${encodeURIComponent ( redirect )}` ;
    } else {
        return redirect ;
    }
} ;
/** 返回，无前一页的返回首页 */
export const goBack = async ( CacheHistory:BBWHistory, router:Router, store:Store<any>, homeUrl:RawLocation, unloginUrl:RawLocation ) => {
    const prevIndex = CacheHistory.currentState.index - 1 ;
    const prevState = CacheHistory.list[prevIndex] ;
    const prevIsAccount = prevState?.location.match( /^\/account\// ) ;
    // 前一个页面不是大作，或前面是登录注册页，未登录，则返回主页
    if ( prevState?.isOld || prevIsAccount || store.state.UserInfo.id === -1 ) {
        if ( store.state.UserInfo.id === -1 ) {
            await jumpPage ( router, homeUrl ) ;
        } else {
            await jumpPage ( router, unloginUrl ) ;
        }
    } else {
        router.back () ;
    }
} ;
/** 跳转到AI创作页 */
export const goToAI = async ( encryptData: string, toUrl = PageAiCreate, key?: string, from?: string, to?: string ) => {
    _hmt.push ( [`_trackEvent`, `ai大作`, `跳转`, `点击`] ) ;
    const token = getToken () ;
    const urlObj = new URL ( toUrl ) ;
    const url = `${urlObj.protocol}//${urlObj.host}/auth?token=${encodeURIComponent ( token )}&redirect=${encodeURIComponent ( toUrl )}`
    console.log( url, '////url' )
    let info = {} ;
    if ( encryptData || key ) {
        info = {
            encrypt_data: encryptData,
            key,
            from,
            to
        } ;
        if ( to === `paint` ) {
            window.open ( `${url}&remark=${encodeURIComponent(JSON.stringify ( info ))}` ) ;
        } else {
            window.open ( url, JSON.stringify ( info ) ) ;
        }
    } else {
        window.open ( url ) ;
    }
} ;
/** 跳转到大库auth页  bigbigcool */
export const goToDakuAuth = ( redirect: string ) => {
    const token: string = getToken () ;
    const url = `${PageDkAuth}?token=${token}&redirect=${encodeURIComponent ( redirect )}` ;
    window.open ( url ) ;
} ;

/** pinlite跳转到AI创作页 */
export const pinliteToAI = async ( data: object, toUrl = PageAiCreate, domain = ServerAiPainting ) => {
    _hmt.push ( [`_trackEvent`, `ai大作`, `跳转`, `点击`] ) ;
    const token = getToken () ;
    // const url = `${PageAiAuth}?token=${token}&redirect=${encodeURIComponent ( toUrl )}`
    const url = `${domain}/auth?token=${token}&redirect=${encodeURIComponent ( toUrl )}`
    window.open ( url, JSON.stringify ({ pinliteData: data } ) ) ;
}

import { ResultItem } from "@scripts/resultItem" ;
import axios from "axios" ;
import { ServerDesigner, ServerWww } from "@constants/servers" ;
import { NetError } from "@scripts/tools" ;
import { stringify } from "querystring" ;

/** 根据图片id获取相似图 */
export const SimilarFetchDataByID = ( isFreeCopyRight:boolean, item:ResultItem, page:number, token:string ):Promise<[Error, ResultItem[]]> => {
    const apiUrl = isFreeCopyRight ? `/similarImgNoCopyrightByImgId` : `/similarImg` ;
    return axios ( {
        url: ServerWww + apiUrl,
        method: `GET`,
        params: {
            imgId: item.id,
            imgUrl: item.bUrl,
            page: page,
            token: token
        }
    } ).then ( res => {
        if ( res.status !== 200 ) { throw new NetError ( `网络错误` ) ; }
        const data = res.data ;
        if ( data.status !== 200 ) {
            throw new Error ( `服务器开小差了，请稍后再试！` ) ;
        }
        const results = data.data.map ( val => {
            const item = new ResultItem ( val ) ;
            return item ;
        } ) ;
        return [null, results] as [Error, ResultItem[]] ;
    } ).catch ( e => {
        return [e, null] ;
    } ) ;
} ;
/** 根据图片id获取相似图 AI */
export const SimilarFetchAISearch = (imgUrl: string, isVip: number, frequency: number, page:number, token:string):Promise<[ Error, ResultItem[] ]> => {
    const apiUrl = `/similarImgAiByUrl` ;
    return axios ( {
        url: ServerWww + apiUrl,
        method: `GET`,
        params: {
            imgUrl: imgUrl,
            isVip: isVip,
            page: page,
            frequency: frequency,
            token: token
        }
    } ).then ( res => {
        if ( res.status !== 200 ) { throw new NetError ( `网络错误` ) ; }
        const data = res.data ;
        if ( data.status !== 200 ) {
            throw new Error ( `服务器开小差了，请稍后再试！` ) ;
        }
        const results = data.data.map ( val => {
            const item = new ResultItem ( val ) ;
            return item ;
        } ) ;
        return [null, results] as [Error, ResultItem[]] ;
    } ).catch ( e => {
        return [e, null] ;
    } ) ;
} ;
/* 根据图片id获取相似大作素材 */
export const SimilarFetchSCSearch = (
    opts: {
        img_id?: string,
        img_url?: string,
        p: number,
        token: string,
        num: number,
        type: number
    } ):Promise<[ Error, ResultItem[] ]> => {
    return axios ( {
        url: `${ServerWww}/relatedMaterial`,
        method: `GET`,
        params: { ...opts }
    } ).then ( res => {
        if ( res.status !== 200 ) { throw new NetError ( `网络错误` ) ; }
        const data = res.data ;
        console.log( data, '结果' ) ;
        if ( data?.status !== 200 ) {
            throw new Error ( `服务器开小差了，请稍后再试！` ) ;
        }
        const results = data?.data.map ( val => {
            const item = new ResultItem ( val ) ;
            return item ;
        } ) ;
        return [null, results] as [Error, ResultItem[]] ;
    } ).catch ( e => {
        return [e, null] ;
    } ) ;
} ;
/** oss上传结果 */
export type SimilarCutImgUploadResult = {
    imgName: string
    ossUrl: string,
    status: number
}
/** 上传base64切图到oss */
export const SimilarCutImgUpload = ( base64:string, imgName:string ):Promise<[Error, SimilarCutImgUploadResult]> => {
    return axios ( {
        url: ServerDesigner + `/img/cutImgUpload`,
        // url: `//designer.bigbigwork.com/img/cutImgUpload`,
        method: `POST`,
        data: stringify ( {
            base64Data: base64,
            imgName: imgName
        } )
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new NetError ( `网络错误` ) ;
        }
        const data:SimilarCutImgUploadResult = res.data ;
        if ( data.status !== 200 ) {
            throw new Error ( `服务器开小差了，请稍后再试！` ) ;
        }
        return [null, data] as [Error, SimilarCutImgUploadResult] ;
    } ).catch ( e => {
        return [e, null] ;
    } ) ;
} ;

/** 根据oss上存储的图片切片来获取相似图 */
export const SimilarFetchDataByOSSUrl = ( isFreeCopyRight:boolean, imgUrl:string, page: number, frequency: number, isVip: number, token: string ):Promise<[Error, ResultItem[]]> => {
    const apiUrl = isFreeCopyRight ? `/similarImgNoCopyrighByUrl` : `/similarImgCut` ;
    return axios ( {
        url: ServerWww + apiUrl,
        method: `GET`,
        params: {
            imgUrl,
            page,
            frequency,
            isVip,
            token
        }
    } ).then ( ( res ) => {
        if ( res.status !== 200 ) { throw new NetError ( `网络错误` ) ; }
        const data = res.data ;
        if ( data.status !== 200 ) {
            throw new Error ( `服务器开小差了，请稍后再试！` ) ;
        }
        const results = data.data.map ( val => {
            const item = new ResultItem ( val ) ;
            return item ;
        } ) ;
        return [null, results] as [Error, ResultItem[]] ;
    } ).catch ( e => {
        return [e, null] ;
    } ) ;
} ;

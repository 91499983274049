import { Module, Store } from "vuex" ;
import { deepToRelativeProtocol } from "@scripts/tools" ;
import { getRedEnvelopeData } from "@scripts/Api" ;
import { LocalStorageRead, LocalStorageWrite } from "@scripts/LocalStorage" ;
import dayjs from "dayjs" ;
import { cookieGet, cookieSet } from "@scripts/CrossDomain" ;
import { getCrossCookie, setCrossCookie } from "@scripts/Guide/GuideUtils" ;

interface RedEnvelopeState {
    ready: boolean
    daojishi: number
    img: string
    img1: string
    img2: string
    openFlag: number
    /* 右侧小红包图片 */
    rightImg: string
    /* 右侧小红包点击跳转链接 */
    url: string
    todoFn: Array<any>,
    isRequesting: boolean,
    /** 本次向导有没有显示过弹红包，避免弹窗冲突 */
    guideShowRedEnvelopes: boolean,
    /** 福利弹窗 */
    dialogFuli:{
        /** 是否显示福利弹窗 */
        show: boolean,
        /** 是否播放从右边小窗【弹出、收起】的动画 */
        sideAni: boolean,
        /** 弹窗组件 */
        component: any,
        /** 上次关闭福利弹窗的时间 */
        closeFuliDialog: number,
        /** 右边红包是否收起 */
        isMin:boolean,
        /** 测试开关，总是显示福利弹窗 */
        test: boolean,
        /** 是否展示右侧福利弹窗 */
        showRightFuli: boolean,
        /** 右侧倒计时总长 */
        rightCountdown: number
    },
    dialogFuliTuyi:{
        /** 是否显示福利弹窗 */
        show: boolean,
        /** 是否播放从右边小窗【弹出、收起】的动画 */
        sideAni: boolean,
        /** 弹窗组件 */
        component: any,
        /** 上次关闭福利弹窗的时间 */
        closeFuliDialog: number,
        /** 右边红包是否收起 */
        isMin:boolean,
        /** 测试开关，总是显示福利弹窗 */
        test: boolean,
        /** 是否展示右侧福利弹窗 */
        showRightFuli: boolean,
        /** 右侧倒计时总长 */
        rightCountdown: number
    },
    dialogKFBigImg:{
        /** 是否显示福利弹窗 */
        show: boolean,
        /** 是否播放从右边小窗【弹出、收起】的动画 */
        sideAni: boolean,
        /** 弹窗组件 */
        component: any,
        /** 上次关闭福利弹窗的时间 */
        closeFuliDialog: number,
        /** 右边红包是否收起 */
        isMin:boolean,
        /** 测试开关，总是显示福利弹窗 */
        test: boolean,
        /** 是否展示右侧福利弹窗 */
        showRightFuli: boolean,
        /** 右侧倒计时总长 */
        rightCountdown: number
    },
    /* 是否显示红包 */
    isShowRedEnvelope: boolean,
    /** pinlite福利弹窗*/
    pdialogFuli:{
        /** 是否显示福利弹窗 */
        show: boolean,
        /** 弹窗组件 */
        component: any,
        /** 上次关闭福利弹窗的时间 */
        closeFuliDialog: number,
        /** 测试开关，总是显示福利弹窗 */
        test: boolean
    }
}

export const RedEnvelope:Module<RedEnvelopeState, any> = {
    namespaced: true,
    state: {
        ready: false,
        daojishi: 0,
        img: ``,
        img1: ``,
        img2: ``,
        openFlag: 1,
        rightImg: ``,
        url: ``,
        todoFn: [],
        isRequesting: false,
        guideShowRedEnvelopes: false,
        dialogFuli: {
            show: false,
            sideAni: false,
            component: null,
            closeFuliDialog: 0,
            isMin: true,
            test: false,
            showRightFuli: true,
            rightCountdown: 1000 * 60 * 5
        },
        dialogFuliTuyi: {
            show: false,
            sideAni: false,
            component: null,
            closeFuliDialog: 0,
            isMin: true,
            test: false,
            showRightFuli: true,
            rightCountdown: 1000 * 60 * 5
        },
        // 大图客服弹窗
        dialogKFBigImg: {
            show: false,
            sideAni: false,
            component: null,
            closeFuliDialog: 0,
            isMin: true,
            test: false,
            showRightFuli: true,
            rightCountdown: 1000 * 60 * 5
        },
        isShowRedEnvelope: false,
        pdialogFuli:{
            show: false,
            /** 弹窗组件 */
            component: null,
            /** 上次关闭福利弹窗的时间 */
            closeFuliDialog: 0,
            /** 测试开关，总是显示福利弹窗 */
            test: false
        }
    },
    mutations: {
        updateIsRequesting: ( state: RedEnvelopeState, payload: boolean ) => {
            state.isRequesting = payload ;
        },
        syncData: ( state, val ) => {
            const data = Object.assign ( {}, val ) ;
            deepToRelativeProtocol ( data ) ;
            state.daojishi = data.daojishi ;
            state.img = data.img ;
            state.img1 = data.img1 ;
            state.img2 = data.img2 ;
            state.openFlag = data.openFlag ;
            state.rightImg = data.rightImg ;
            state.url = data.url ;
            state.ready = true ;
        },
        updateTodoFn: ( state: RedEnvelopeState, payload: {add?: boolean, todoFn?: object} ) => {
            if ( payload.add ) {
                state.todoFn = [...state.todoFn, payload.todoFn] ;
            } else {
                state.todoFn = [] ;
            }
        },
        /** 设置当前先到有没有显示过红包 */
        setGuideShowRedEnvelopes: ( state: RedEnvelopeState, payload:boolean ) => {
            state.guideShowRedEnvelopes = payload ;
        },
        /** 更新福利弹窗设置 */
        updateDialogFuli: ( state, payload:{show?:boolean, sideAni?:boolean, component?:any, closeFuliDialog?:number } ) => {
            state.dialogFuli = { ...state.dialogFuli, ...payload } ;
        },
        /** 更新图意福利弹窗设置 */
        updateDialogFuliTuyi: ( state, payload:{show?:boolean, sideAni?:boolean, component?:any, closeFuliDialog?:number } ) => {
            state.dialogFuliTuyi = { ...state.dialogFuliTuyi, ...payload } ;
        },
        /** 更新大图客服弹窗设置 */
        updateDialogKFBigImg: ( state, payload:{show?:boolean, sideAni?:boolean, component?:any, closeFuliDialog?:number } ) => {
            state.dialogKFBigImg = { ...state.dialogKFBigImg, ...payload } ;
        },
        /*更新 pinlite 福利弹窗*/
        updatePDialogFuli: ( state, payload:{show?:boolean, component?:any, closeFuliDialog?:number } ) => {
            state.pdialogFuli = { ...state.pdialogFuli, ...payload } ;
        },
        /** 设置是否开启福利测试 */
        setFuliTest: ( state, payload:boolean ) => {
            state.dialogFuli.test = payload ;
        },
        updateDialogRed:(state,payload) =>{
            state.isShowRedEnvelope = payload;
        }
    },
    actions: {
        /** 打开或关闭福利弹窗 */
        toggleDialogFuli: async ( context, { show, sideAni }: {show:boolean, sideAni:boolean} ) => {
            try {
                if ( show ) {
                    if ( !context.state.dialogFuli.component ) throw new Error ( `没有找到福利弹窗组件，请先异步引入` ) ;
                    // 记录已弹过福利弹窗
                    const end = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
                    const diff = end.subtract ( new Date ().getTime (), `millisecond` ).valueOf () ;
                    const now = new Date ().getTime () ;
                    await setCrossCookie ( `fuli`, true, { expires: new Date ( now + diff ) } ) ;
                    // LocalStorageWrite ( `fuli`, ``, true, { expires: diff, unit: `millisecond` } ) ;
                    context.commit ( `updateDialogFuli`, { show, sideAni } ) ;
                } else {
                    // 记录关闭弹窗时间
                    await context.dispatch ( `saveCloseFuli` ) ;
                    // 收起右边福利小窗
                    // await setCrossCookie ( `fuli_min`, `true`, { expires: 7 } ) ;
                    window.setTimeout ( async () => {
                        // context.commit ( `updateDialogFuli`, { isMin: true } ) ;
                        context.commit ( `updateDialogFuli`, { show, sideAni } ) ;
                    } ) ;
                }
            } catch ( e ) {
                throw e ;
            }
        },
        /** 打开或关闭图意福利弹窗 */
        toggleDialogFuliTuyi: async ( context, { show, sideAni }: {show:boolean, sideAni:boolean} ) => {
            try {
                if ( show ) {
                    if ( !context.state.dialogFuliTuyi.component ) throw new Error ( `没有找到福利弹窗组件，请先异步引入` ) ;
                    // 记录已弹过福利弹窗
                    const end = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
                    const diff = end.subtract ( new Date ().getTime (), `millisecond` ).valueOf () ;
                    const now = new Date ().getTime () ;
                    await setCrossCookie ( `fuliTuyi`, true, { expires: new Date ( now + diff ) } ) ;
                    // LocalStorageWrite ( `fuli`, ``, true, { expires: diff, unit: `millisecond` } ) ;
                    context.commit ( `updateDialogFuliTuyi`, { show, sideAni } ) ;
                } else {
                    // 记录关闭弹窗时间
                    await context.dispatch ( `saveCloseFuliTuyi` ) ;
                    // 收起右边福利小窗
                    // await setCrossCookie ( `fuli_min`, `true`, { expires: 7 } ) ;
                    window.setTimeout ( async () => {
                        // context.commit ( `updateDialogFuli`, { isMin: true } ) ;
                        context.commit ( `updateDialogFuliTuyi`, { show, sideAni } ) ;
                    } ) ;
                }
            } catch ( e ) {
                throw e ;
            }
        },
        /** 打开或关闭大图客服弹窗 */
        toggleDialogBigImg: async ( context, { show, sideAni }: {show:boolean, sideAni:boolean} ) => {
            try {
                if ( show ) {
                    if ( !context.state.dialogKFBigImg.component ) throw new Error ( `没有找到福利弹窗组件，请先异步引入` ) ;
                    // 记录已弹过福利弹窗
                    const end = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
                    const diff = end.subtract ( new Date ().getTime (), `millisecond` ).valueOf () ;
                    const now = new Date ().getTime () ;
                    await setCrossCookie ( `kfBigImg`, true, { expires: new Date ( now + diff ) } ) ;
                    // LocalStorageWrite ( `fuli`, ``, true, { expires: diff, unit: `millisecond` } ) ;
                    context.commit ( `updateDialogKFBigImg`, { show, sideAni } ) ;
                } else {
                    // 记录关闭弹窗时间
                    await context.dispatch ( `saveCloseBigImg` ) ;
                    // 收起右边福利小窗
                    // await setCrossCookie ( `fuli_min`, `true`, { expires: 7 } ) ;
                    window.setTimeout ( async () => {
                        context.commit ( `updateDialogKFBigImg`, { show, sideAni } ) ;
                    } ) ;
                }
            } catch ( e ) {
                throw e ;
            }
        },
        /** 记录关闭福利弹窗的时间 */
        saveCloseFuli: async ( context ) => {
            const now = new Date ().getTime () ;
            const closeTime = await getCrossCookie ( `fuliCloseTime` ) ;
            // const closeTime = LocalStorageRead ( `fuliCloseTime`, `` ) ;
            // 如果没有记录过关闭时间，记录关闭福利弹窗时间
            if ( !closeTime ) {
                // 记录已弹过福利弹窗
                const end = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
                const diff = end.subtract ( new Date ().getTime (), `millisecond` ).valueOf () ;
                context.commit ( `updateDialogFuli`, { closeFuliDialog: now } ) ;
                await setCrossCookie ( `fuliCloseTime`, now, { expires: new Date ( now + diff ) } ) ;
                // LocalStorageWrite (
                //     `fuliCloseTime`,
                //     ``,
                //     now,
                //     { expires: context.state.dialogFuli.rightCountdown, unit: `millisecond` }
                // ) ;
            }
        },
        /** 记录关闭福利弹窗的时间 */
        saveCloseFuliTuyi: async ( context ) => {
            const now = new Date ().getTime () ;
            const closeTime = await getCrossCookie ( `fuliTuyiCloseTime` ) ;
            // const closeTime = LocalStorageRead ( `fuliCloseTime`, `` ) ;
            // 如果没有记录过关闭时间，记录关闭福利弹窗时间
            if ( !closeTime ) {
                // 记录已弹过福利弹窗
                const end = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
                const diff = end.subtract ( new Date ().getTime (), `millisecond` ).valueOf () ;
                context.commit ( `updateDialogFuliTuyi`, { closeFuliDialog: now } ) ;
                await setCrossCookie ( `fuliTuyiCloseTime`, now, { expires: new Date ( now + diff ) } ) ;
                // LocalStorageWrite (
                //     `fuliCloseTime`,
                //     ``,
                //     now,
                //     { expires: context.state.dialogFuli.rightCountdown, unit: `millisecond` }
                // ) ;
            }
        },
        /** 记录关闭大图客服弹窗的时间 */
        saveCloseBigImg: async ( context ) => {
            const now = new Date ().getTime () ;
            const closeTime = await getCrossCookie ( `kfBigImgCloseTime` ) ;
            // const closeTime = LocalStorageRead ( `fuliCloseTime`, `` ) ;
            // 如果没有记录过关闭时间，记录关闭福利弹窗时间
            if ( !closeTime ) {
                // 记录已弹过福利弹窗
                const end = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
                const diff = end.subtract ( new Date ().getTime (), `millisecond` ).valueOf () ;
                context.commit ( `updateDialogKFBigImg`, { closeFuliDialog: now } ) ;
                await setCrossCookie ( `kfBigImgCloseTime`, now, { expires: new Date ( now + diff ) } ) ;
            }
        },
        toggleDialogRed: async (context, isshow) => {
            context.commit('updateDialogRed',isshow);
        },
        /** 打开或关闭pinlite福利弹窗 */
        togglePDialogFuli: async ( context, { show }: {show:boolean} ) => {
            try {
                if ( show ) {
                    if ( !context.state.pdialogFuli.component ) throw new Error ( `没有找到福利弹窗组件，请先异步引入` ) ;
                    // 记录已弹过福利弹窗
                    const end = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
                    const diff = end.subtract ( new Date ().getTime (), `millisecond` ).valueOf () ;
                    const now = new Date ().getTime () ;
                    // @ts-ignore
                    await cookieSet ( `pinliteFuli`, true, { expires: new Date ( now + diff ) } ) ;
                    // LocalStorageWrite ( `fuli`, ``, true, { expires: diff, unit: `millisecond` } ) ;
                    context.commit ( `updatePDialogFuli`, { show } ) ;
                } else {
                    // 记录关闭弹窗时间
                    await context.dispatch ( `saveClosePFuli` ) ;
                    // 收起右边福利小窗
                    // await setCrossCookie ( `fuli_min`, `true`, { expires: 7 } ) ;
                    window.setTimeout ( async () => {
                        // context.commit ( `updateDialogFuli`, { isMin: true } ) ;
                        context.commit ( `updatePDialogFuli`, { show } ) ;
                    } ) ;
                }
            } catch ( e ) {
                throw e ;
            }
        },
        /** 记录关闭pinlite福利弹窗的时间 */
        saveClosePFuli: async ( context ) => {
            const now = new Date ().getTime () ;
            const closeTime = await cookieGet ( `pfuliCloseTime` ) ;
            // const closeTime = LocalStorageRead ( `fuliCloseTime`, `` ) ;
            // 如果没有记录过关闭时间，记录关闭福利弹窗时间
            if ( !closeTime ) {
                // 记录已弹过福利弹窗
                const end = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
                const diff = end.subtract ( new Date ().getTime (), `millisecond` ).valueOf () ;
                context.commit ( `updatePDialogFuli`, { closeFuliDialog: now } ) ;
                // @ts-ignore
                await cookieSet ( `pfuliCloseTime`, now, { expires: new Date ( now + diff ) } ) ;
            }
        },
        /** 从localstorage获取上次关闭pinlite福利弹窗的时间 */
        getClosePFuliDialog: async ( context ) => {
            // @ts-ignore
            const time:string = await cookieGet ( `pfuliCloseTime` ) ;
            // const now = new Date ().getTime () ;
            context.commit ( `updatePDialogFuli`, { closeFuliDialog: JSON.parse ( time ) } ) ;
            /*if ( time && ( now - parseInt ( time ) ) < context.state.dialogFuli.rightCountdown ) {
                context.commit ( `updatePDialogFuli`, { closeFuliDialog: JSON.parse ( time ) } ) ;
            }*/
        },
        /** 从localstorage获取上次关闭福利弹窗的时间 */
        getCloseFuliDialog: async ( context ) => {
            const time:string = await getCrossCookie ( `fuliCloseTime` ) ;
            const now = new Date ().getTime () ;
            if ( time && ( now - parseInt ( time ) ) < context.state.dialogFuli.rightCountdown ) {
                context.commit ( `updateDialogFuli`, { closeFuliDialog: JSON.parse ( time ) } ) ;
            }
        },
        getCloseFuliTuyiDialog: async ( context ) => {
            const time:string = await getCrossCookie ( `fuliTuyiCloseTime` ) ;
            const now = new Date ().getTime () ;
            if ( time && ( now - parseInt ( time ) ) < context.state.dialogFuliTuyi.rightCountdown ) {
                context.commit ( `updateDialogFuliTuyi`, { closeFuliDialog: JSON.parse ( time ) } ) ;
            }
        },
        /** 从localstorage获取上次关闭大图客服福利弹窗的时间 */
        getCloseKFBigImgDialog: async ( context ) => {
            const time:string = await getCrossCookie ( `kfBigImgCloseTime` ) ;
            const now = new Date ().getTime () ;
            if ( time && ( now - parseInt ( time ) ) < context.state.dialogKFBigImg.rightCountdown ) {
                context.commit ( `updateDialogKFBigImg`, { closeFuliDialog: JSON.parse ( time ) } ) ;
            }
        },
        FetchRedEnvelope: async ( context, val ) => {
            const clearToDoFn = () => {
                if ( context.state.todoFn.length ) {
                    context.state.todoFn.forEach ( fn => fn ( false ) ) ;
                    context.commit ( `updateTodoFn`, {} ) ;
                }
            } ;
            if ( context.state.isRequesting ) {
                return new Promise ( resolve => {
                    context.commit ( `updateTodoFn`, { add: true, todoFn: resolve } ) ;
                } ) ;
            }
            if ( !context.state.isRequesting && !context.state.ready ) {
                context.commit ( `updateIsRequesting`, true ) ;
            }
            if ( context.state.ready ) return Promise.resolve ( context.state ) ;
            try {
                const res = await getRedEnvelopeData () ;
                context.commit ( `syncData`, res ) ;
                context.commit ( `updateIsRequesting`, false ) ;
                clearToDoFn () ;
                return deepToRelativeProtocol ( res ) ;
            } catch ( e ) {
                throw e ;
            }
        }
    },
    getters: {
        // 右下角福利弹窗的终止时间，5分钟倒计时
        rightFuliEndTime: ( state ) => {
            if ( state.dialogFuli.closeFuliDialog > 0 ) {
                return state.dialogFuli.closeFuliDialog + state.dialogFuli.rightCountdown ;
            } else {
                return 0 ;
            }
        },
        // 右下角福利弹窗的终止时间，5分钟倒计时
        rightFuliTuyiEndTime: ( state ) => {
            if ( state.dialogFuliTuyi.closeFuliDialog > 0) {
                return state.dialogFuliTuyi.closeFuliDialog + state.dialogFuliTuyi.rightCountdown ;
            } else {
                return 0 ;
            }
        }
    }
} ;
export const CreateRedEnvelope = ( store:Store<unknown> ):void => {
    if ( store.hasModule ( `RedEnvelope` ) ) return ;
    /** 在store上注册模块 */
    store.registerModule ( `RedEnvelope`, RedEnvelope ) ;
} ;
